import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from, gql, } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import CsrfManager from '@root/javascript/common/csrf-manager';
import { RetryLink } from '@apollo/client/link/retry';
import hasOwnProperty from '@root/javascript/util/has-own-property';
const ERROR_EXTENSIONS = {
    OBJECT_NOT_FOUND: 'OBJECT.NOT_FOUND',
};
const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 3,
        retryIf: (error) => {
            if (hasOwnProperty(error, 'statusCode') && error.statusCode === 429) {
                return true;
            }
            return false;
        },
    },
});
const httpLink = new HttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
});
const authMiddleware = new ApolloLink((operation, forward) => {
    if (CsrfManager.expired) {
        return null;
    }
    operation.setContext({
        headers: {
            'X-CSRF-Token': CsrfManager.token,
        },
    });
    return forward(operation);
});
const errorInterceptor = onError(({ networkError }) => {
    if (networkError && 'statusCode' in networkError) {
        switch (networkError.statusCode) {
            case 401:
                CsrfManager.markExpired();
                break;
            default: // do nothing
        }
    }
    // if (graphQLErrors.length > 0) {
    //   const errorCodes = new Set(['undefinedField', 'selectionMismatch', 'unknownType']);
    //   graphQLErrors.find((e) => e.extensions && errorCodes.has(e.extensions.code));
    //   console.log(graphQLErrors);
    // }
});
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
        authMiddleware,
        retryLink,
        errorInterceptor,
        httpLink,
    ]),
});
export default client;
export { ERROR_EXTENSIONS, gql };
